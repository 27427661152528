import { Team } from './team';

export const TEAMMEMBERS: Team[] = [
  {
    gender: "female",
    name: {
      title: "Ms",
      first: "Latife",
      last: "Oraloğlu"
    },
    location: {
      street: {
        number: 591,
        name: "Anafartalar Cd"
      },
      city: "Antalya",
      state: "Tunceli",
      country: "Turkey",
      postcode: "58913",
      coordinates: {
        latitude: "71.8983",
        longitude: "117.2723"
      },
      timezone: {
        offset: "+3:30",
        description: "Tehran"
      }
    },
    email: "latife.oraloglu@example.com",
    login: {
      uuid: "9ad9011c-8608-4c00-b24a-685f90bdf9b0",
      username: "redgoose596",
      password: "cupoi",
      salt: "xdIVbcSj",
      md5: "8d1de71cdc6c012eb21bb4b2122a796e", sha1: "5751d4e07c26bf28b01c85784911df5d8e8877ed", sha256: "9f82f9b7538ab2a52e28e0a1a36f5ad5fceb5fb5ca19f3355b87bae9b3d7a7d5"
    },
    dob: {
      date: "1961-12-06T05:54:49.418Z",
      age: 59
    },
    registered: {
      date: "2019-02-14T08:45:50.205Z",
      age: 1
    },
    phone: "(078)-957-0114",
    cell: "(086)-899-7667",
    id: {
      name: "",
      value: 1
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/58.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/58.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/58.jpg"
    },
    nat: "TR"
  },
  {
    gender: "female",
    name: {
      title: "Ms",
      first: "Isabella",
      last: "Addy"
    },
    location: {
      street: {
        number: 6852,
        name: "Dalhousie Ave"
      },
      city: "Stratford",
      state: "Saskatchewan",
      country: "Canada",
      postcode: "U0N 0B4",
      coordinates: {
        latitude: "-65.0446",
        longitude: "-124.7402"
      },
      timezone: {
        offset: "-3:30",
        description: "Newfoundland"
      }
    },
    email: "isabella.addy@example.com",
    login: {
      uuid: "70eadffb-99b2-4b80-95a5-dde3f0b97140",
      username: "bluesnake296",
      password: "414141",
      salt: "n8dRLEhs",
      md5: "c2fa4b3249fbe1ca113bafac3948b6a8", sha1: "898cceceb7b1ecc8f72f1058b39fdeb38263856c", sha256: "55a0003206b9e546e3c35a8ec8890ba08a1b2a73feb861451154beb22956fd26"
    },
    dob: {
      date: "1998-01-03T05:35:45.570Z",
      age: 22
    },
    registered: {
      date: "2002-12-12T23:09:47.144Z",
      age: 18
    },
    phone: "055-437-1411",
    cell: "706-085-7761",
    id: {
      name: "",
      value: 2
    },
    picture: {
      large: "https://randomuser.me/api/portraits/women/67.jpg",
      medium: "https://randomuser.me/api/portraits/med/women/67.jpg",
      thumbnail: "https://randomuser.me/api/portraits/thumb/women/67.jpg"
    },
    nat: "CA"
  },
  { gender: "female", 
  name: { 
    title: "Miss", 
    first: "Amalie", 
    last: "Christensen" 
  }, 
  location: { 
    street: { 
      number: 6054, 
      name: "Sundtoften" 
    }, 
    city: "Århus C.", 
    state: "Hovedstaden", 
    country: "Denmark", 
    postcode: "95137", 
    coordinates: { 
      latitude: "-53.0238", 
      longitude: "115.0627" 
    }, 
    timezone: { 
      offset: "+2:00", 
      description: "Kaliningrad, South Africa" 
    } 
  }, 
  email: "amalie.christensen@example.com", 
  login: { 
    uuid: "3f0b0997-6c17-422b-8a0e-8bba2c180174", 
    username: "silverfrog339", 
    password: "kswbdu", 
    salt: "fzHo7LN3", 
    md5: "f28c6aa1e74e3e6cfcabf87ffbfbca62", 
    sha1: "76e995fb7f5c5a5331cdfe1dffae4a3ec06439dd", 
    sha256: "811a805418a5362ecb092340e40d179a7d4241d135afc8294c977288f73d972b" 
  }, 
  dob: { 
    date: "1962-08-30T23:15:04.264Z", 
    age: 58 
  }, 
  registered: { 
    date: "2015-06-19T15:14:21.900Z", 
    age: 5 
  }, 
  phone: "99843398", 
  cell: "27525653", 
  id: { 
    name: "CPR", 
    value: 3 
  }, 
  picture: { 
    large: "https://randomuser.me/api/portraits/women/16.jpg",
    medium: "https://randomuser.me/api/portraits/med/women/16.jpg", 
    thumbnail: "https://randomuser.me/api/portraits/thumb/women/16.jpg" 
  }, 
  nat: "DK" 
}, 
{ 
  gender: "male", 
  name: { 
    title: "Mr", 
    first: "Philippe", 
    last: "Liu" 
  }, 
  location: { 
    street: { 
      number: 1715, 
      name: "Balmoral St" 
    }, 
    city: "Inwood", 
    state: "Newfoundland and Labrador", 
    country: "Canada", 
    postcode: "B4W 1E4", 
    coordinates: { 
      latitude: "32.1208", 
      longitude: "-160.6699" 
    }, 
    timezone: { 
      offset: "+3:00", 
      description: "Baghdad, Riyadh, Moscow, St. Petersburg" 
    } 
  }, 
  email: "philippe.liu@example.com", 
  login: { 
    uuid: "0289cf4f-1683-4f9a-a8c5-45c2fec655ed", 
    username: "happyzebra443", 
    password: "joshua1", 
    salt: "sT7wHA9d", 
    md5: "0e99840a32acf146aab10903d1e2cb95", 
    sha1: "6fc22a4544d78f34fc5ac43bd7dd45231146fa73", 
    sha256: "f00d5a711768e93a2de6122ba0365bc12031e74157c9d47b5419a677dde05b56" }, 
    dob: { 
      date: "1959-01-12T08:03:44.836Z", 
      age: 61 
    }, 
    registered: { 
      date: "2011-06-21T05:20:55.011Z", 
      age: 9 
    }, 
    phone: "524-778-6120", 
    cell: "164-299-2278", 
    id: { 
      name: "", 
      value: 4 
    }, 
    picture: { 
      large: "https://randomuser.me/api/portraits/men/83.jpg", 
      medium: "https://randomuser.me/api/portraits/med/men/83.jpg", 
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/83.jpg" 
    }, 
    nat: "CA" 
  }, 
  { 
    gender: "male", 
    name: { 
      title: "Mr", 
      first: "Ethan", 
      last: "Watts" 
    }, 
    location: { 
      street: { 
        number: 8627, 
        name: "Northaven Rd" 
      }, 
      city: "Saginaw", 
      state: "Nevada", 
      country: "United States", 
      postcode: "85318", 
      coordinates: { 
        latitude: "-38.7366", 
        longitude: "78.9677" 
      }, 
      timezone: { 
        offset: "-2:00", 
        description: "Mid-Atlantic" 
      } 
    }, 
    email: "ethan.watts@example.com", 
    login: { 
      uuid: "838a85de-4127-4c88-a276-04bf586f076f", 
      username: "orangesnake999", 
      password: "baggio", 
      salt: "YFgfTgfl", 
      md5: "b57b4b3542b7f5ef3aafbcc3fb423706", 
      sha1: "76128622452335f63bff9c0a003cd5a99f3a5931", 
      sha256: "1d5c1873a7fad43ed2e8f1701d6ee0b435b370a8971895f950ebe11681f0c98b" 
    }, 
    dob: { 
      date: "1955-10-05T13:20:19.965Z", 
      age: 65 
    }, 
    registered: { 
      date: "2016-11-25T05:30:43.534Z", 
      age: 4 
    }, 
    phone: "(213)-789-9413", 
    cell: "(697)-764-5230", 
    id: { 
      name: "SSN", 
      value: 5 
    }, 
    picture: { 
      large: "https://randomuser.me/api/portraits/men/30.jpg",
      medium: "https://randomuser.me/api/portraits/med/men/30.jpg", 
      thumbnail: "https://randomuser.me/api/portraits/thumb/men/30.jpg" 
    }, 
    nat: "US" 
  }, 
  { 
    gender: "female", 
    name: { 
      title: "Ms", 
      first: "Angela", 
      last: "Bravo" }, 
      location: { 
        street: { 
          number: 7437, 
          name: "Avenida de La Albufera" 
        }, 
        city: "Móstoles", 
        state: "Navarra", 
        country: "Spain", 
        postcode: "49155", 
        coordinates: { 
          latitude: "-21.3935", 
          longitude: "-84.3010" 
        }, 
        timezone: { 
          offset: "-11:00", 
          description: "Midway Island, Samoa" 
        } 
      }, 
      email: "angela.bravo@example.com", 
      login: { 
        uuid: "8d946bcc-9230-4297-8ee3-3bc17e227f05", 
        username: "tinymeercat141", 
        password: "passwort", 
        salt: "056I8tZc", 
        md5: "f486f771d3b14da2b67fd80fa2d5ba26", 
        sha1: "40e9552b86ea76ebed0f50ff3585ca646bf180b3", 
        sha256: "031c984b4ea3654a1bfff341b5602036bb7f205ce8957baf44c7db4e53b07588" 
      }, 
      dob: { 
        date: "1997-05-22T17:18:43.571Z", 
        age: 23 
      }, 
      registered: { 
        date: "2012-11-29T12:49:43.605Z", 
        age: 8 
      }, 
      phone: "982-556-719", 
      cell: "663-687-282", 
      id: { 
        name: "DNI", 
        value: 6 
      }, 
      picture: { 
        large: "https://randomuser.me/api/portraits/women/61.jpg", 
        medium: "https://randomuser.me/api/portraits/med/women/61.jpg", 
        thumbnail: "https://randomuser.me/api/portraits/thumb/women/61.jpg" 
      }, 
      nat: "ES" 
    }, 
    { 
      gender: 
      "female", 
      name: { 
        title: "Ms", 
        first: "Nelli", 
        last: "Polon" 
      }, 
      location: { 
        street: { 
          number: 6305, 
          name: "Aleksanterinkatu" 
        }, 
        city: "Geta", 
        state: "South Karelia", 
        country: "Finland", 
        postcode: "51312", 
        coordinates: { 
          latitude: "80.6630", 
          longitude: "-43.9566" 
        }, 
        timezone: { 
          offset: "-5:00", 
          description: "Eastern Time (US & Canada), Bogota, Lima" 
        } 
      }, 
      email: "nelli.polon@example.com", 
      login: { 
        uuid: "d8c8dd81-1b11-4318-9f3d-4425ceff715b", 
        username: "crazycat911", 
        password: "fireblad", 
        salt: "PX7RsgYI", 
        md5: "8ee4d9e0b7935ffe72897de765cedf02", 
        sha1: "7ed129cd87cb9cf4676542f056605870fbb5e457", 
        sha256: "83bef83305441c01d5104dd33d514234728579c7dc3600ad15044f7c46e91e25" 
      }, 
      dob: { 
        date: "1996-05-10T11:57:13.257Z", 
        age: 24 
      }, 
      registered: { 
        date: "2011-04-05T03:22:39.726Z", 
        age: 9 
      }, 
      phone: "09-004-723", 
      cell: "040-838-56-41", 
      id: { 
        name: "HETU", 
        value: 7
      }, 
      picture: { 
        large: 
        "https://randomuser.me/api/portraits/women/15.jpg", 
        medium: "https://randomuser.me/api/portraits/med/women/15.jpg", 
        thumbnail: "https://randomuser.me/api/portraits/thumb/women/15.jpg" 
      }, 
      nat: "FI" 
    }, 
    { 
      gender: "male", 
      name: { 
        title: "Mr", 
        first: "Roland", 
        last: "Edwards" 
      }, 
      location: { 
        street: { 
          number: 701, 
          name: "Manor Road" 
        }, 
        city: "Oranmore", 
        state: "Galway", 
        country: "Ireland", 
        postcode: "86050", 
        coordinates: { 
          latitude: "72.1704", 
          longitude: "102.6674" 
        }, 
        timezone: { 
          offset: "+8:00", 
          description: "Beijing, Perth, Singapore, Hong Kong" 
        } 
      }, 
      email: "roland.edwards@example.com", 
      login: { 
        uuid: "c9793511-220f-44a7-86b1-1f1b29e6fee8", 
        username: "bigduck319", 
        password: "hastings", 
        salt: "W1pmKIkb", 
        md5: "2609790c2c2fe6237df5e9782a4c15d2", 
        sha1: "8bf47058e0add746a5ebcbb2432f07bb5bf9ceb6", 
        sha256: "4568c942cd52dcbded7bbd1bdea182f869415caf837948c9a3d469f05ab24c25" 
      }, 
      dob: { 
        date: "1958-12-20T10:54:02.801Z", 
        age: 62 
      }, 
      registered: { 
        date: "2006-10-12T19:03:59.235Z", 
        age: 14 
      }, 
      phone: "041-488-1851", 
      cell: "081-396-9294", 
      id: { 
        name: "PPS", 
        value: 8 
      }, 
      picture: { 
        large: "https://randomuser.me/api/portraits/men/8.jpg", 
        medium: "https://randomuser.me/api/portraits/med/men/8.jpg", 
        thumbnail: "https://randomuser.me/api/portraits/thumb/men/8.jpg" 
      }, 
      nat: "IE" 
    }, 
    { 
      gender: "female", 
      name: { 
        title: "Ms", 
        first: "Sandra", 
        last: "Velasco" 
      }, 
      location: { 
        street: { 
          number: 507, 
          name: "Calle del Pez" 
        }, 
        city: "La Palma", 
        state: "Comunidad de Madrid", 
        country: "Spain", 
        postcode: "98614", 
        coordinates: { 
          latitude: "84.1778", 
          longitude: "-64.0860" 
        }, 
        timezone: { 
          offset: "-4:00", 
          description: "Atlantic Time (Canada), Caracas, La Paz" 
        } 
      }, 
      email: "sandra.velasco@example.com", 
      login: { 
        uuid: "d0ae90f9-ad38-499b-9fa5-adc4e39f1b5c", 
        username: "bigduck296", 
        password: "120676", 
        salt: "D2li5TWW", 
        md5: "d997a9d12997ae19d693bfa2056cd5fd", 
        sha1: "4a91e391465a13dadd4f8c96aba2e306a3ce17a0", 
        sha256: "f1fc41da30e4d57fea19affe7f413c45894cec8940db819fc8e25a04c561e786" 
      }, 
      dob: { 
        date: "1951-06-17T19:16:44.264Z", 
        age: 69 
      }, 
      registered: { 
        date: "2007-07-22T21:27:43.725Z", 
        age: 13 
      }, 
      phone: "908-587-363", 
      cell: "604-766-627", 
      id: { 
        name: "DNI", 
        value: 9 
      }, 
      picture: { 
        large: "https://randomuser.me/api/portraits/women/17.jpg", 
        medium: "https://randomuser.me/api/portraits/med/women/17.jpg", 
        thumbnail: "https://randomuser.me/api/portraits/thumb/women/17.jpg" 
      }, 
      nat: "ES" 
    }, 
    { 
      gender: "female", 
      name: { 
        title: "Mrs", 
        first: "Hilla", 
        last: "Pesola" 
      }, 
      location: { 
        street: { 
          number: 9741, 
          name: "Hermiankatu" 
        }, 
        city: "Kiikoinen", 
        state: "Northern Savonia", 
        country: "Finland", 
        postcode: "58589", 
        coordinates: { 
          latitude: "-11.6597", 
          longitude: "92.0087" 
        }, 
        timezone: { 
          offset: "-1:00", 
          description: "Azores, Cape Verde Islands" 
        } 
      }, 
      email: "hilla.pesola@example.com", 
      login: { 
        uuid: "67dabe4a-00b7-4acf-b41d-8542ef82e106", 
        username: "angryostrich801", 
        password: "lawman", 
        salt: "ZcHgjwQt", 
        md5: "816ffdae4cb90db62c55766aebf94bf3", 
        sha1: "8345f6156c437756c39774408bd64f3fde9b3dee", 
        sha256: "d3589c0f0db036a217f1b7d9e42d6ccf802c95a9bb767dbdbe071deec317f3a6" 
      }, 
      dob: { 
        date: "1948-05-14T19:52:06.323Z", 
        age: 72 
      }, 
      registered: { 
        date: "2008-11-11T21:05:36.221Z", 
        age: 12 
      }, 
      phone: "09-415-452", 
      cell: "048-709-07-30", 
      id: { 
        name: "HETU", 
        value: 10 
      }, 
      picture: { 
        large: "https://randomuser.me/api/portraits/women/39.jpg", 
        medium: "https://randomuser.me/api/portraits/med/women/39.jpg", 
        thumbnail: "https://randomuser.me/api/portraits/thumb/women/39.jpg" 
      }, 
      nat: "FI" 
    }
];