import { Component, OnInit } from '@angular/core';

import { Team } from '../team';

// Opcao 2 - acesso ao conteudo do ficheiro mock via service
import { TeamService } from '../team.service';

// adicionar o servico messagens a este componente
import { MessageService } from '../message.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  team: Team[];
  selectedTeamMember: Team;

  users = [];

  constructor(
    private teamService: TeamService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    // this.getTeam();
    this.getUsers();
  }

  // metodo para obter os valores importados de modo assincrono7y
  // getTeam(): void {
  //   this.teamService.getTeam()
  //       .subscribe(team => this.team = team);
  // }

  getUsers(): void {
    this.teamService.getUsers()
        .subscribe((data: any) => {
          console.log(data.results);
          this.users = data.results;
        });
        // .subscribe(team => this.team = team);
        
  }

  onSelect(teamMember: Team): void {
    this.selectedTeamMember = teamMember;
    // menssagem quando o utilizador clicar uma opcao
    // this.messageService.add(`Clicou no heroi: ${teamMember.name.first}`);
    // console.log(`Clicou no membro: ${teamMember.name.first}`);
    console.log(`Clicou no membro:`);
  }

}
