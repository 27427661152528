import { Component, OnInit } from '@angular/core';

import { HeroService } from '../hero.service';

@Component({
  selector: 'app-where',
  templateUrl: './where.component.html',
  styleUrls: ['./where.component.scss']
})
export class WhereComponent implements OnInit {

  constructor(
    private heroService: HeroService,
    ) { }

    ngOnInit() {
      this.getHeroes();
    }
  
    // metodo para obter os valores importados de modo assincrono
    getHeroes(): void {
      this.heroService.getHeroes();
    }

}
