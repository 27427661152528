import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// para indicar que a interface "Hero" é importada do componente hereos
// é aqui que se faz a ligacao ao componente-pai
import { Hero } from '../hero';

import { HeroService }  from '../hero.service';

@Component({
  selector: 'app-hero-detail',
  templateUrl: './hero-detail.component.html',
  styleUrls: ['./hero-detail.component.scss']
})
export class HeroDetailComponent implements OnInit {

  // para que se saiba que ha informacao que provem do componente heroes
  // é aqui que se faz a ligacao ao componente-pai
  @Input() hero: Hero;

  constructor(
    // 1. The ActivatedRoute holds information about the route to this instance of the HeroDetailComponent. This component is interested in the route's parameters extracted from the URL. The "id" parameter is the id of the hero to display.
    // 2. The HeroService gets hero data from the remote server and this component will use it to get the hero-to-display.
    // 3. The location is an Angular service for interacting with the browser
    private route: ActivatedRoute,
    private heroService: HeroService,
    private location: Location
  ) { }

  ngOnInit():void {
    this.getHero();
  }
  
  // declare function getHero
  getHero(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.heroService.getHero(id)
      .subscribe(hero => this.hero = hero);
  }

  goBack(): void {
    this.location.back();
  }

  // para guardar o valor existente ou atualizado na caixa de texto como nome do hero
  save(): void {
    // this.heroService.updateHero(this.hero)
    //   .subscribe(() => this.goBack());
  }
}
