import { Component, OnInit, Input } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// para indicar que a interface "Hero" é importada do componente hereos
// é aqui que se faz a ligacao ao componente-pai
import { Team } from '../team';

import { TeamService }  from '../team.service';

@Component({
  selector: 'app-teammember-detail',
  templateUrl: './teammember-detail.component.html',
  styleUrls: ['./teammember-detail.component.scss']
})
export class TeammemberDetailComponent implements OnInit {

  // para que se saiba que ha informacao que provem do componente heroes
  // é aqui que se faz a ligacao ao componente-pai
  @Input() team: Team;

  constructor(
    // 1. The ActivatedRoute holds information about the route to this instance of the HeroDetailComponent. This component is interested in the route's parameters extracted from the URL. The "id" parameter is the id of the hero to display.
    // 2. The HeroService gets hero data from the remote server and this component will use it to get the hero-to-display.
    // 3. The location is an Angular service for interacting with the browser
    private route: ActivatedRoute,
    private heroService: TeamService,
    private location: Location
  ) { }

  ngOnInit():void {
    this.getTeammember();
  }
  
  // declare function getHero
  getTeammember(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.heroService.getTeammember(id)
      .subscribe(team => this.team = team);
  }

  goBack(): void {
    this.location.back();
  }

}
