import { Component, OnInit } from '@angular/core';

import { Hero } from '../hero';

// Opcao 1 - acesso direto ao ficheiro mock
// import { HEROES } from '../mock-heroes';

// Opcao 2 - acesso ao conteudo do ficheiro mock via service
import { HeroService } from '../hero.service';

// adicionar o servico messagens a este componente
import { MessageService } from '../message.service';

@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html',
  styleUrls: ['./heroes.component.scss']
})
export class HeroesComponent implements OnInit {

  heroes: Hero[];
  selectedHero: Hero;

  constructor(
    private heroService: HeroService, 
    private messageService: MessageService
    ) { }

  ngOnInit() {
    this.getHeroes();
  }

  // metodo para obter os valores importados de modo assincrono7y
  getHeroes(): void {
    this.heroService.getHeroes()
        .subscribe(heroes => this.heroes = heroes);
  }

  onSelect(hero: Hero): void {
    this.selectedHero = hero;
    // menssagem quando o utilizador clicar uma opcao
    this.messageService.add(`Clicou no heroi: ${hero.name}`);
    console.log(`Clicou no heroi: ${hero.name}`);
  }

  // add(name: string): void {
  //   name = name.trim();
  //   if (!name) { return; }
  //   this.heroService.addHero({ name } as Hero)
  //     .subscribe(hero => {
  //       this.heroes.push(hero);
  //     });
  // }

  // delete(hero: Hero): void {
  //   this.heroes = this.heroes.filter(h => h !== hero);
  //   this.heroService.deleteHero(hero).subscribe();
  // }

}
