import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  messages: string[] = [];

  constructor() { }

  add(message: string): void {
    this.messages.push(message);
  }

  clearMessage(message: string): void {
    let index = this.messages.indexOf(message);
    console.log(index);
    this.messages.splice(index,1);

    // for(let i=0; i < this.messages.length; i++) {
    //   if(this.messages[i]==message) {
      
    //   }
    // }
    // this.messages = [];
  }

  clear(): void {
    this.messages = [];
  }

}
