// imports de sistema
import { Injectable } from '@angular/core';
// importar a funcionalidade observable 
import { Observable, of } from 'rxjs';
// importar funcionalidades de http request para este servico
import { HttpClient, HttpHeaders } from '@angular/common/http';
// To catch errors, you "pipe" the observable result from http.get() through an RxJS catchError() operator.
import { catchError, map, tap } from 'rxjs/operators';

// imports de projeto
// importar o que nos interessa
import { MessageService } from './message.service';
import { Hero } from './hero';
import { HEROES } from './mock-heroes';



@Injectable({
  providedIn: 'root'
})

export class HeroService {

  constructor(
    private messageService: MessageService
  ) { }

  private heroesUrl = 'api/heroes';  // URL to web api

  // metodo para obter a informacao pretendida
  getHeroes(): Observable<Hero[]> {
    // send the message _after_ fetching the heroes
    this.messageService.add('Informação importada com sucesso!');
    console.log('Informação importada com sucesso!');

    // OPCAO 1: para ler do ficheiro mock-heroes.ts
    return of(HEROES);

  }

  getHero(id: number): Observable<Hero> {
    // TODO: send the message _after_ fetching the hero
    // this.messageService.add(`HeroService: fetched hero id=${id}`);
    this.messageService.add(`Selecionou o heroi com ID=${id}`);

    // OPCAO 1: para ler do ficheiro mock-heroes.ts
    return of(HEROES.find(hero => hero.id === id));

  }

}
