import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';

// declaracao de todos os componentes existentes na minha aplicacao
// este componente e o componente mae. Existe sempre!
import { AppComponent } from './app.component';
// componentes criados pelo utilizador (ainda que automaticamente)
import { HeroesComponent } from './heroes/heroes.component';
import { HeroDetailComponent } from './hero-detail/hero-detail.component';
import { MessagesComponent } from './messages/messages.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { WhereComponent } from './where/where.component';
import { Service1Component } from './service1/service1.component';
import { Service2Component } from './service2/service2.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeroSearchComponent } from './hero-search/hero-search.component';
import { TeamComponent } from './team/team.component';
import { TeammemberDetailComponent } from './teammember-detail/teammember-detail.component';

@NgModule({
  declarations: [
    // declaracao de todos os componentes
    // nativo
    AppComponent,
    // adicionado pelo utilzador (ainda que automaticamente)
    HeroesComponent,
    HeroDetailComponent,
    MessagesComponent,
    AboutComponent,
    ServicesComponent,
    WhereComponent,
    Service1Component,
    Service2Component,
    DashboardComponent,
    HeroSearchComponent,
    TeamComponent,
    TeammemberDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
