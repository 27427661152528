import { Injectable } from '@angular/core';
// importar a funcionalidade observable 
import { Observable, of } from 'rxjs';

// imports de projeto
// importar o que nos interessa
import { MessageService } from './message.service';
import { Team } from './team';
import { TEAMMEMBERS } from './mock-team';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) { }

  // metodo para obter a informacao pretendida
  getTeam(): Observable<Team[]> {
    // send the message _after_ fetching the heroes
    this.messageService.add('Informação importada com sucesso!');
    console.log('Informação importada com sucesso!');

    // OPCAO 1: para ler do ficheiro mock-heroes.ts
    return of(TEAMMEMBERS);
  }

  getTeammember(id: number): Observable<Team> {
    // TODO: send the message _after_ fetching the hero
    // this.messageService.add(`TeamService: fetched team member id=${id}`);
    this.messageService.add(`Selecionou o elemento de equipa com ID=${id}`);

    // OPCAO 1: para ler do ficheiro mock-heroes.ts
    return of(TEAMMEMBERS.find(team => team.id.value === id));

  }

  getUsers() {
    return this.http.get('https://randomuser.me/api/?results=5');
  }
}
