import { Component, OnInit } from '@angular/core';

// importar o servico mostrar mensagem
import { MessageService } from '../message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  // aqui definimos com opublic de modo a que o html possa aceder ao servico para poder mostrar a mensagem!
  constructor(public messageService: MessageService) { }

  ngOnInit() {
  }

}
