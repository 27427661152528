import { Component, OnInit } from '@angular/core';

import { HeroService } from '../hero.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(
    private heroService: HeroService,
    ) { }

    ngOnInit() {
      this.getHeroes();
    }
  
    // metodo para obter os valores importados de modo assincrono
    getHeroes(): void {
      this.heroService.getHeroes();
    }

}
