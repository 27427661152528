import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { HeroesComponent } from './heroes/heroes.component';
import { HeroDetailComponent } from './hero-detail/hero-detail.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { WhereComponent } from './where/where.component';
import { TeammemberDetailComponent } from './teammember-detail/teammember-detail.component';

const routes: Routes = [
  // rota por defeito
  { path: '', redirectTo: '/heroes', pathMatch: 'full' },
  // rotas especificadas pelo programador
  // { path: 'dashboard', component: DashboardComponent },
  { path: 'heroes', component: HeroesComponent },
  { path: 'heroes/detail/:id', component: HeroDetailComponent },
  { path: 'about', component: AboutComponent },
  { path: 'about/detail/:id', component: TeammemberDetailComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'where', component: WhereComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
